export const environment = {
  authority: "",
  clientID: "",
  redirectUri: "",
  postLogoutRedirectUri: "",
  protectedResourceMap: [],
  production: true,
  appInsights: {
    instrumentationKey : '3707a383-ba6b-4550-8b15-76884a06e94a'
  },
  defaultCountryId: 1,
  appBaseUrl : window.location.protocol + "//" + window.location.host + "/" ,
  notificationIntervalInMinute: 2,
  receiveSMSIntervalInMinute:2,
  enableRingCentralWidget: true,
  esignatureBaseURL:"https://preprodonlinerental-qa.azurewebsites.net/sign",
  ringcentralWidgetURL:"https://esoftringcentral-qa.azurewebsites.net/adapter.js",
  isFingerPrintEnabled: false,
  ringcentralIVREnabled: '1',
  ringcentralClientNumbers: '',
  isDLScannerEnabled: false,
  dlScannerlicenseKey:'',
  paymentGetCCTStatusInterval: 10,
  cardNotPresentInterval :5,
  paymentMaxWaitTime: 2,
  allowToCreatePortalLogin: true,
  consentScopes:[],
  multipleCountryForNearestLocation: true,
  autoSaveInquiryTime:'1',
  isTopazSignatureEnabled: false,
  syncfusionKey: "ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5WdEFjXnxadHNVT2Ze",
  azureMapsSubscriptionKey: '',
  tdLocalHostArchitectureFacilities : [56],
  enableNewPaymentArchitecture : false,
  prePayMaxLimit: 60

};
