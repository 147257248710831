import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserSessionService } from '../services/usersession.service';
import { PowerBiService } from '../services/power-bi.service';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()

export class PowerBIAuthGuard implements CanActivate {

  constructor(private userSessionService: UserSessionService, 
              private powerBIService:PowerBiService,
              private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
      const url: string = state.url; 
      if(this.userSessionService.loggedInUserDetails!=null)
        {
          if(this.userSessionService.loggedInUserDetails.IsPowerBIReportAccess)
            {
              return of(this.userSessionService.loggedInUserDetails.IsPowerBIReportAccess);
            }
            else{
              this.router.navigateByUrl('');
              return of(false);
            }
        }
        else{
          return this.powerBIService.hasPermission().pipe(
            map(hasAccess => {
              if (hasAccess) {
                return true;
              } else {
                // Redirect the user if they don't have permission
                this.router.navigateByUrl('');
                return false;
              }
            })
          );
        }
  }
}