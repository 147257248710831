import { Injectable, EventEmitter, Output } from '@angular/core';
import { IUserGroup, ISaveDeleteResponse } from '../Shared/interface';
import { UserGroupService } from './userGroups.service';
import { ILoggedInUser } from '../Shared/models/ProspectModel';
import { IMailServerSettingModel, IPhoneSystemTypeModel } from '../Shared/interface2';
import { GeneralService } from './general.service';
import { DatePipe } from '@angular/common';
import { UserService } from './user.service';
import { CalltrackerService } from './operations/calltracker.service';
import { CompanysettingService } from 'src/app/services/companysetting.service';
import { MailServerService } from './mailServer.service';
import { CallScriptService } from './call-script.service';
import { PowerBiService } from './power-bi.service';

@Injectable(
  {
    providedIn: 'root'
  }
)

export class UserSessionService {
  @Output() callScriptsFetched: EventEmitter<any> = new EventEmitter<any>();
  @Output() userDetailsChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() mailSettingChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() dayEndEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() userLoginEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() companysettingEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() dayendsettingEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() facilityLateAndLienEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() notificationEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() newsListEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() unitChangeEmitter: EventEmitter<any> = new EventEmitter<any>();
  storeList = [];
  userDetails: IUserGroup;
  isCallcenterAgent: boolean = false;
  userSessionData = {
    UserId: 0,
    FirstName: '',
    LastName: '',
    Initials: '',
    Code: '',
    Inactive: false,
    UserGroup: {
      GId: 0,
      GCode: '',
      GName: '',
      CallCenterGroup: false,
      GeolocationBasedLogin: false,
      IPBasedLogin: false,
      UserList: [],
      ModuleList: [],
      IndividualPermission: {
        UserGroupOptionLimits: [],
        UserGroupRefundReversalLimits: [],
        UserGroupFeesAndChargesLimits: [],
        UserGroupAllowanceItemsLimits: [],
        UserGroupDiscountPermissions:  []
      }
    },
    Phone: '',
    Email: '',
    UserName: '',
    ScreenPopupPhoneNumber: '',
    StorageFacilities: [],
    DeleteFlag: false,
    LastAccessedStoreId: 0,
    StoreGroupUsers: [],
    AllCallScriptData: []
  };
  userFunctionPermissions = [];
  companySettingDetails: any;
  dayendSettingDetails: any;
  facilityLateAndLienSetting: any;
  newslist: any;
  callTrackerUserData = {
    PhoneId: 0,
    FacilityId: 0,
    TenantID: null,
    ExistingTenantCall: false,
    PhoneNumber: '',
    OutboundCall: false,
    AdSourceID: 0,
    PhoneSystem: 1,
    CallRecordingId: '',
    CallDuration: 0,
    IncompleteCall: false,
    IncompleteCallUserId: 1,
    IncompleteCallConverted: false,
    IncompleteCallConvertedDatetime: new Date(),
    IncompleteCallConvertedUserId: 1,
    Campaign: '',
    TrackingNumber: '',
    CallSID: '',
    LeaseNumber: null,
    ReservationNumber: null,
    InquiryNumber: null,
    CallCenterIntegrationId: 0
  };
  public loaderIcon: Promise<boolean>;
  public isScreenPopupTab = false;
  //Global Service values
  lovList = [];
  countriesList = [];
  statesList = [];
  IsNewList = 0;
  callCentreAgentFacilityId;
  bearerToken;
  loggedInUserDetails: ILoggedInUser;
  defaultPhoneSystem: IPhoneSystemTypeModel;
  mailServerSetting;
  smsActivated = false;
  userGroupsDetails: any[] = [];
  userGroupList: any[] = [];
  allUsersList: any[] = [];
  allStoresListFromGetStores = [];
  currentStoreCountryId:number;
  PowerBIReportList=[];

  constructor(private userGroupService: UserGroupService,
    private generalService: GeneralService,
    private callTrackerService: CalltrackerService,
    private userService: UserService,
    private companySettingService: CompanysettingService,
    public mailServerSettings: MailServerService,
    private callScriptService: CallScriptService) {
  }
  /**
   * This method is called when user logs in
   * @param userData : Logged in User Data
   */

  FillUserSessionData(userData: ILoggedInUser, geolocationBasedLoginDetails, ipMatchedStoreId) {
    this.loggedInUserDetails = userData;
    this.userSessionData.UserId = userData.UserDetails.Id;
    this.loggedInUserDetails.IsPowerBIReportAccess=true; //PowerBI Settings TODO: Need to get the permission from API
    this.isCallcenterAgent = userData.UserGroupCallCenter;
    this.userSessionData.LastAccessedStoreId = userData.UserGroupCallCenter ? null : userData.UserDetails.LastAccessedFacility;
    this.userSessionData.StorageFacilities = userData.UserDetails.StorageFacilities;
    this.userSessionData.FirstName = userData.UserDetails.FirstName;
    this.userSessionData.LastName = userData.UserDetails.LastName;
    this.generalService.UserFname = userData.UserDetails.FirstName;
    this.generalService.UserLname = userData.UserDetails.LastName;
  
    this.userSessionData.UserGroup.GeolocationBasedLogin = userData.GeolocationBasedLogin;
    this.userSessionData.UserGroup.CallCenterGroup = userData.UserGroupCallCenter;
    this.userSessionData.UserGroup.IPBasedLogin = userData.IPBasedLogin;
    this.userSessionData.UserGroup.IndividualPermission.UserGroupOptionLimits = userData.UserPermissions.UserGroupOptionLimits;
    this.userSessionData.UserGroup.IndividualPermission.UserGroupRefundReversalLimits = userData.UserPermissions.UserGroupRefundReversalLimits;
    this.userSessionData.UserGroup.IndividualPermission.UserGroupFeesAndChargesLimits = userData.UserPermissions.UserGroupFeesAndChargesLimits;
    this.userSessionData.UserGroup.IndividualPermission.UserGroupAllowanceItemsLimits = userData.UserPermissions.UserGroupAllowanceItemsLimits;

    this.userSessionData.StoreGroupUsers = userData.UserDetails.StoreGroupUsers;
    if (userData.GeolocationBasedLogin && geolocationBasedLoginDetails !== null) {
      this.userSessionData.LastAccessedStoreId = geolocationBasedLoginDetails.Id;
      userData.UserDetails.LastAccessedFacility = geolocationBasedLoginDetails.Id
      this.userService.SaveLastAccessedFacility(geolocationBasedLoginDetails.Id).subscribe((response: ISaveDeleteResponse) => {
      });
      this.userDetailsChange.emit(userData);
    } else if (userData.IPBasedLogin) {
      if (ipMatchedStoreId > 0) {
        this.userSessionData.LastAccessedStoreId = ipMatchedStoreId;
        userData.UserDetails.LastAccessedFacility = ipMatchedStoreId
        this.userService.SaveLastAccessedFacility(ipMatchedStoreId).subscribe((response: ISaveDeleteResponse) => {
        });
        setTimeout(() => {
          this.userDetailsChange.emit(userData);
        }, 3000);
      }
    } else {
      if (userData) {
        this.userDetailsChange.emit(userData);
      }
    }
    let facilityId = userData.UserGroupCallCenter ? 0 : this.userSessionData.LastAccessedStoreId;
    this.getAllCallScripts(facilityId);
    this.userFunctionPermissions = userData.UserPermissions.Functions;
    this.generalService.UserPermissions = userData.UserPermissions;
    this.generalService.userGroupPermissions.emit(userData.UserPermissions);
    if (this.companySettingDetails == undefined) {
      this.getCompanysettings();
    }
    if (this.dayendSettingDetails == undefined) {
      this.getDayendSettings(this.userSessionData.LastAccessedStoreId);
    }
    if (this.facilityLateAndLienSetting == undefined) {
      this.getFacilityLateAndLienSettings(this.userSessionData.LastAccessedStoreId);
    }
    if (!this.mailServerSetting) {
      this.getMailServerSetting();
    }
    this.getNewItem(this.userSessionData.LastAccessedStoreId);
  }

  getAllCallScripts(facilityId) {
    this.callScriptService.GetAllCallScriptDetails(facilityId).subscribe((response: []) => {
      if (response && response.length) {
        this.userSessionData.AllCallScriptData = response;
      } else {
        this.userSessionData.AllCallScriptData = [];
      }
      this.callScriptsFetched.emit(true);
    }, err => {
      this.userSessionData.AllCallScriptData = [];
      this.callScriptsFetched.emit(true);
    });
  }

  getCompanysettings() {
    this.companySettingService.GetCompanySettings().subscribe((response) => {
      this.companySettingDetails = response;
      this.companysettingEmitter.emit(this.companySettingDetails);
    });
  }

  getDayendSettings(facId) {
    this.companySettingService.GetFacilityDayEndSettings(facId).subscribe((response) => {
      this.dayendSettingDetails = response;
      this.dayendsettingEmitter.emit(this.dayendSettingDetails);
    });
  }

  getFacilityLateAndLienSettings(facId: number) {
    this.companySettingService.getLateAndLienSettings(facId).subscribe(res => {
      this.facilityLateAndLienSetting = res;
      this.facilityLateAndLienEmitter.emit(this.facilityLateAndLienSetting);
    });
  }

  getNewItem(facId) {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    const datepipe = new DatePipe('en');
    const startdate = datepipe.transform(date, 'MM/dd/yyyy');
    if (facId) {
      this.IsNewList++;
      if (this.userGroupService != undefined) {
        this.loaderIcon = Promise.resolve(true);
        this.userGroupService.getNewslist(this.userSessionData.LastAccessedStoreId, startdate).subscribe((response: any) => {
          this.newslist = response;
          this.newsListEmitter.emit(this.newslist);
          this.loaderIcon = Promise.resolve(false);
        })
      }
    } else if (this.isCallcenterAgent) {
      this.IsNewList++;
      if (this.userGroupService) {
        this.loaderIcon = Promise.resolve(true);
        this.userGroupService.getNewslist(0, startdate).subscribe((response: any) => {
          this.newslist = response;
          this.newsListEmitter.emit(this.newslist);
          this.loaderIcon = Promise.resolve(false);
        })
      }
    }
  }

  getUserGroup(): IUserGroup {
    return this.userSessionData == null ? null : this.userSessionData.UserGroup;
  }

  getUserSession(cb) {
    return cb(this.userSessionData);
  }

  getUserRights(functionId) {
    if (this.userFunctionPermissions.length) {
      let functObj = this.userFunctionPermissions.find(o => o.FunctionId === functionId);
      if (functObj) {
        return functObj.UserGroupRights;
      }
    }
    return null;
  }

  InServiceGetCompanySettings() {
    return this.companySettingDetails;
  }

  FillCallTrackerDetails(data) {
    if (this.isCallcenterAgent && data.FacilityId) {
      this.callCentreAgentFacilityId = data.FacilityId;
    } else if (!data.FacilityId && this.callCentreAgentFacilityId) {
      data.FacilityId = this.callCentreAgentFacilityId;
    }
    if (data.PhoneId) {
      this.callTrackerUserData.PhoneId = data.PhoneId;
    }
    if (data.FacilityId) {
      this.callTrackerUserData.FacilityId = data.FacilityId;
    }
    if (data.TenantID) {
      this.callTrackerUserData.TenantID = data.TenantID;
    } else {
      let item = localStorage.getItem('calltracker');
      let ctData = JSON.parse(item);
      if (ctData && ctData.TenantID && ctData.TenantID > 0) {
        data.TenantID = ctData.TenantID;
        this.callTrackerUserData.TenantID = ctData.TenantID;
      }
    }
    if (data.ExistingTenantCall) {
      this.callTrackerUserData.ExistingTenantCall = data.ExistingTenantCall;
    }
    if (data.PhoneNumber) {
      this.callTrackerUserData.PhoneNumber = data.PhoneNumber;
    }
    if (data.OutboundCall) {
      this.callTrackerUserData.OutboundCall = data.OutboundCall;
    }
    if (data.AdSourceID) {
      this.callTrackerUserData.AdSourceID = data.AdSourceID;
    }
    if (data.PhoneSystem) {
      this.callTrackerUserData.PhoneSystem = data.PhoneSystem;
    }
    if (data.CallRecordingId) {
      this.callTrackerUserData.CallRecordingId = data.CallRecordingId;
    }
    if (data.CallDuration) {
      this.callTrackerUserData.CallDuration = data.CallDuration;
    }
    if (data.IncompleteCall) {
      this.callTrackerUserData.IncompleteCall = data.IncompleteCall;
    }
    if (data.IncompleteCallUserId) {
      this.callTrackerUserData.IncompleteCallUserId = data.IncompleteCallUserId;
    }
    if (data.IncompleteCallConverted) {
      this.callTrackerUserData.IncompleteCallConverted = data.IncompleteCallConverted;
    }
    if (data.IncompleteCallConvertedDatetime) {
      this.callTrackerUserData.IncompleteCallConvertedDatetime = data.IncompleteCallConvertedDatetime;
    }
    if (data.IncompleteCallConvertedUserId) {
      this.callTrackerUserData.IncompleteCallConvertedUserId = data.IncompleteCallConvertedUserId;
    }
    if (data.Campaign) {
      this.callTrackerUserData.Campaign = data.Campaign;
    }
    if (data.TrackingNumber) {
      this.callTrackerUserData.TrackingNumber = data.TrackingNumber;
    }
    if (data.CallSID) {
      this.callTrackerUserData.CallSID = data.CallSID;
    }
    if (data.LeaseNumber) {
      this.callTrackerUserData.LeaseNumber = data.LeaseNumber;
    }
    if (data.ReservationNumber) {
      this.callTrackerUserData.ReservationNumber = data.ReservationNumber;
    }
    if (data.InquiryNumber) {
      this.callTrackerUserData.InquiryNumber = data.InquiryNumber;
    }
    if (data.CallCenterIntegrationId) {
      this.callTrackerUserData.CallCenterIntegrationId = data.CallCenterIntegrationId;
    }
    localStorage.setItem('calltracker', JSON.stringify(this.callTrackerUserData));
    if (data.CallRecordingId && data.PhoneId != 0 && data.PhoneSystem == 466) {
      this.saveCallTrackerDetails(data);
    }
  }

  saveCallTrackerDetails(data) {
    this.callTrackerService.SavePhoneCall(data).subscribe((response: ISaveDeleteResponse) => {
      if (response.Success) {
        this.callTrackerUserData.PhoneId = response.Id;

      }
    });
  }

  getCallTrackerSessionDetails() {
    localStorage.get('calltracker', JSON.stringify(this.callTrackerUserData));
    return this.callTrackerUserData;
  }

  resetCallTrackerSessionData() {
    this.callTrackerUserData = {
      PhoneId: 0,
      FacilityId: 0,
      TenantID: null,
      ExistingTenantCall: false,
      PhoneNumber: '',
      OutboundCall: false,
      AdSourceID: 0,
      PhoneSystem: 1,
      CallRecordingId: '',
      CallDuration: 0,
      IncompleteCall: false,
      IncompleteCallUserId: 0,
      IncompleteCallConverted: false,
      IncompleteCallConvertedDatetime: new Date(),
      IncompleteCallConvertedUserId: 0,
      Campaign: '',
      TrackingNumber: '',
      CallSID: '',
      LeaseNumber: null,
      ReservationNumber: null,
      InquiryNumber: null,
      CallCenterIntegrationId: 0
    };
    localStorage.setItem('calltracker', JSON.stringify(this.callTrackerUserData));
  }

  getLovListForType(typeId) {
    if (this.lovList.length) {
      let lovArr = this.lovList.filter((ele) => ele.TypeId == typeId);
      return lovArr;
    }
    return [];
  }

  getStatesForCountry(countryId) {
    if (this.statesList.length) {
      let stateList = this.statesList.filter((ele) => ele.CountryId == countryId);
      return stateList;
    }
    return [];
  }

  getMailServerSetting() {
    this.mailServerSettings.getMailServerSettingDetails().subscribe((response: IMailServerSettingModel) => {
      this.mailServerSetting = response;
      if (this.mailServerSetting && this.mailServerSetting.ActivateSmsService && this.mailServerSetting.SmsOption == 356) {
        this.smsActivated = true;
      } else {
        this.smsActivated = false;
      }
      this.mailSettingChange.emit(this.mailServerSetting);
    });
  }

  addUserGroupDetails(groupId: any, usersList: any[]) {
    this.userGroupsDetails[groupId] = usersList;
  }

  getUserGroupDetails(groupId: any) {
    return this.userGroupsDetails[groupId];
  }
}

