import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppInsightsService } from '../app-insights.service';
import { CalltrackerService } from './calltracker.service';
import { UserSessionService } from '../usersession.service';
import { IRingcentralNumbers, ISaveDeleteResponse } from 'src/app/Shared/interface';
import { Router } from '@angular/router';
import { MailServerService } from '../mailServer.service';
import {AppService} from '../app.service';
import { IMailServerSettingModel } from 'src/app/Shared/interface2';
import { IProspectMarketing } from '../../Shared/models/ProspectModel';


@Injectable({
    providedIn: 'root',
})


export class RingCentralCTService {
    private baseURL = this.appService.config.apiBaseUrl;
    public selectedPhoneSystem = 466;
    public myChildWindow;
    mailServerSetting: IMailServerSettingModel;
    selectedUnitDetails;
    marketingInfoPhone: IProspectMarketing;
    constructor(private http: HttpClient, private appinsights: AppInsightsService, private router: Router,
        private userSessionService: UserSessionService, private callTrackerService: CalltrackerService,
        private mailServerSettings: MailServerService, private appService: AppService) {
    }

    openRingCentralWidget() {
        var opened = localStorage.getItem('standalone-widget-opened');
        var windowFeatures = 'menubar=no,location=no, minimizable=no,scrollbars=yes, width=' + 300 + ', height=' + 520;
        if (!opened) {
          this.myChildWindow = window.open('"../../assets/Standalone/standalone-widget.html#focus', 'embeddable-standalone-widget', windowFeatures);
            return;
        } else if(this.myChildWindow) {
            this.myChildWindow.focus();
        }
    }

    setMessageToRingCentralWidget(message) {
        localStorage.setItem('widget-command-key', JSON.stringify(message));
        localStorage.removeItem('widget-command-key');
    }

    ListenEvents() {
        window.addEventListener('storage', (event) => {

            if (event.key === 'message-transport-key' && event.newValue && event.newValue !== '') {
                this.onRingCentralWidgetMessageEvent(JSON.parse(event.newValue));
            }
        });

        // Listen for click to dial
    }

    onRingCentralWidgetMessageEvent(data) {

        switch (data.type) {
            case 'rc-call-ring-notify':
                // get call when user gets a ringing call
                //Commented below line code by ganesh, Reason :: Multiple popups were appearing
                // console.log(localStorage.getItem('standalone-widget-opened') + ' standalone-widget-opened');
                // this.openRingCentralWidget();
                // Uncommenting below line code by Felix, Reason :: To open pop-up while ringing
                if (data.call.direction === 'Inbound') {
                    this.userSessionService.resetCallTrackerSessionData();
                    this.inbountOrOutbound(data);
                }

                break;
            case 'rc-call-init-notify':
                // get call when user creates a call from dial
                break;
            case 'rc-call-start-notify':
                // get call when a incoming call is accepted or a outbound call is connected
                // Requirement is to invoke call on call ringing so commenting this code.
                // this.userSessionService.resetCallTrackerSessionData();
                // this.inbountOrOutbound(data);
                // Removed Inbound condition by Felix, Reason :: already handled in above code
                if(data.call.direction === 'Outbound'){
                    this.userSessionService.resetCallTrackerSessionData();
                        this.inbountOrOutbound(data);
                }
                break;
            case 'rc-call-hold-notify':
                // get call when user holds a call
                break;
            case 'rc-call-resume-notify':
                // get call when user unholds call
                break;
            case 'rc-call-end-notify':
                // get call on call end event

                // this.finalCalltrackerSave();

                this.UpdateCall(data);
                //this.userSessionService.resetCallTrackerSessionData();
                break;
            default:
                break;
        }
    }

    popupInvokeForRingcentral(number, firstName, sid, trackingNumber, phoneSystem, popupType) {
        let userID = 1;
        if(this.userSessionService.userSessionData.UserId && this.userSessionService.userSessionData.UserId!=0){
          userID = this.userSessionService.userSessionData.UserId;
        }
        this.userSessionService.resetCallTrackerSessionData();
        var phoneSystemID = 0;
        if (phoneSystem == 'RC') {
            phoneSystemID = this.selectedPhoneSystem;
        }
        const objCallTracker = {
            PhoneId: 0,
            OutboundCall: popupType == 'Inbound' ? false : true,
            PhoneSystem: phoneSystemID,
            CallDuration: 0,
            CallSID: sid,
            CallRecordingId: sid,
            TrackingNumber: trackingNumber,
            FacilityId: this.selectedUnitDetails ? this.selectedUnitDetails.FacilityId : this.userSessionService.userSessionData.LastAccessedStoreId,
            PhoneNumber: number,
            AdSourceID: this.marketingInfoPhone && this.marketingInfoPhone.AdSourceListModel.length > 1 ? this.marketingInfoPhone.AdSourceListModel[0].Id: undefined,
            IncompleteCall: false,
            IncompleteCallUserId: userID,
            IncompleteCallConverted: false,
            IncompleteCallConvertedDatetime: new Date(),
            IncompleteCallConvertedUserId: userID,
            CallCenterIntegrationId: 542
        }
        this.userSessionService.FillCallTrackerDetails(objCallTracker);
        let item = localStorage.getItem('calltracker');
        if (item) {
            let ctData = JSON.parse(item);
            if (ctData.CallRecordingId) {
                this.saveCallTrackerDetails(ctData, number, firstName, sid, trackingNumber, phoneSystem, popupType);
                const urlNavigate = '/prospect/screenpopup?phone=' + number + '&firstName=' + firstName + '&phoneCallSID=' + sid + '&adPhone=' + trackingNumber + '&phoneSystem=' + phoneSystem + '&popupType=' + popupType;
                this.router.navigate([]).then(result => { window.open(urlNavigate, '_blank'); });
            }
        }
    }

    inbountOrOutbound(data) {
        if (data.call.direction === 'Inbound') {
            var sessionIdkey = localStorage.getItem('Inbound-sessionIdkey');
            var strPhone = data.call.from;
            if (strPhone.startsWith("+1")) {
                strPhone = strPhone.substring(2);
            }
            var strPhoneTO = data.call.to;
            this.mailServerSettings.getMailServerSettingDetails().subscribe((response: IMailServerSettingModel) => {
              if (response && response.CallRecordingIntegration == 542) {
                this.callTrackerService.GetMappingNumber(strPhoneTO).subscribe((response) => {
                  if (response && response.FacilityId.length > 0 &&
                    response.FacilityId.filter(facility => facility == this.userSessionService.userSessionData.LastAccessedStoreId))
                  //  let jsnFilter: IRingcentralNumbers[] = [];
                  //  jsnFilter = JSON.parse(environment.ringcentralClientNumbers);
                  // strPhoneTO = jsnFilter.find((e)=> e.PhoneNumber == strPhoneTO).ActualNumber;
                  strPhoneTO = response.PhoneNumber
                });
              }
            });
            if (strPhoneTO.startsWith("+1")) {
                strPhoneTO = strPhoneTO.substring(2);
            } else if (strPhoneTO.startsWith("1")) {
                strPhoneTO = strPhoneTO.substring(1);
                strPhoneTO = strPhoneTO.slice(0, -4);
            }
            if (sessionIdkey !== data.call.partyData.sessionId) {
                localStorage.setItem('Inbound-sessionIdkey', data.call.partyData.sessionId);
                this.popupInvokeForRingcentral(strPhone, "", data.call.partyData.sessionId, strPhoneTO, 'RC', 'Inbound');

            }
        }
        else if (data.call.direction === 'Outbound') {

            var sessionIdkey = localStorage.getItem('Outbound-sessionIdkey');
            var strPhone = data.call.to;
            if (strPhone.startsWith("+1")) {
                strPhone = strPhone.substring(2);
            }
            var strPhoneTO = data.call.from;
            this.mailServerSettings.getMailServerSettingDetails().subscribe((response: IMailServerSettingModel) => {
              if (response && response.CallRecordingIntegration == 542) {
                this.callTrackerService.GetMappingNumber(strPhoneTO).subscribe((response) => {
                  if (response && response.FacilityId.length > 0 &&
                    response.FacilityId.filter(facility => facility == this.userSessionService.userSessionData.LastAccessedStoreId))
                  //  let jsnFilter: IRingcentralNumbers[] = [];
                  //  jsnFilter = JSON.parse(environment.ringcentralClientNumbers);
                  // strPhoneTO = jsnFilter.find((e)=> e.PhoneNumber == strPhoneTO).ActualNumber;
                  strPhoneTO = response.PhoneNumber
                });
              }
            });
            if (strPhoneTO.startsWith("+1")) {
                strPhoneTO = strPhoneTO.substring(2);
            } else if (strPhoneTO.startsWith("1")) {
                strPhoneTO = strPhoneTO.substring(1);
                strPhoneTO = strPhoneTO.slice(0, -4);
            }
            if (sessionIdkey !== data.call.partyData.sessionId) {
                localStorage.setItem('Outbound-sessionIdkey', data.call.partyData.sessionId);
                this.popupInvokeForRingcentral(strPhone, "", data.call.partyData.sessionId, strPhoneTO, 'RC', 'Outbound');
            }
        }
    }

    saveCallTrackerDetails(data, number, firstName, sid, trackingNumber, phoneSystem, popupType) {
        var phoneCallsDetails;
        phoneCallsDetails = data;
        this.callTrackerService.SavePhoneCall(phoneCallsDetails).subscribe((response: ISaveDeleteResponse) => {
            if (response.Success) {
                const objCallTracker = {
                    PhoneId: response.Id,
                    OutboundCall: popupType == 'Inbound' ? false : true,
                    PhoneSystem: this.selectedPhoneSystem,
                    CallDuration: 0,
                    CallSID: sid,
                    CallRecordingId: sid,
                    TrackingNumber: trackingNumber,
                    AdSourceID: this.marketingInfoPhone && this.marketingInfoPhone.AdSourceListModel.length > 1 ? this.marketingInfoPhone.AdSourceListModel[0].Id: undefined,
                    FacilityId: this.selectedUnitDetails ? this.selectedUnitDetails.FacilityId : this.userSessionService.userSessionData.LastAccessedStoreId,
                    PhoneNumber: number,
                    IncompleteCall: false,
                    IncompleteCallUserId: 1,
                    IncompleteCallConverted: false,
                    IncompleteCallConvertedDatetime: new Date(),
                    IncompleteCallConvertedUserId: 1,
                    ExistingTenantCall: false
                }
                this.userSessionService.FillCallTrackerDetails(objCallTracker);
            }
        });
    }

    UpdatePhoneCall() {
        let item = localStorage.getItem('calltracker');
        if (item) {
            let ctData = JSON.parse(item);
            if (ctData.PhoneId && ctData.PhoneId != 0) {
                this.callTrackerService.SavePhoneCall(ctData).subscribe((response: ISaveDeleteResponse) => {
                    if (response.Success) {
                        this.callTrackerService.UpdatePhoneCall(ctData.PhoneId).subscribe((response: any) => {
                            if (response) {
                                localStorage.removeItem('calltracker');
                                this.userSessionService.resetCallTrackerSessionData();
                                localStorage.removeItem('Inbound-sessionIdkey');
                                localStorage.removeItem('Outbound-sessionIdkey');
                            }
                        });
                    }
                });
            }
        }
    }

    UpdateCall(data) {
        if (data.call.direction === 'Inbound') {
            var sessionIdkey = localStorage.getItem('Inbound-sessionIdkey');
            if (sessionIdkey == data.call.partyData.sessionId) {
                localStorage.setItem('Inbound-sessionIdkey','reset');
                this.UpdatePhoneCall();
            }
        }
        else if (data.call.direction === 'Outbound') {
            var sessionIdkey = localStorage.getItem('Outbound-sessionIdkey');
            if (sessionIdkey == data.call.partyData.sessionId) {
               localStorage.setItem('Outbound-sessionIdkey', 'reset');
                this.UpdatePhoneCall();
            }
        }
    }

    captureNumberOnRCWidget(phoneNumber){
      let msg={ type: 'rc-adapter-new-call', phoneNumber: phoneNumber, toCall: false }
      // In production, DO NOT use '*', use toe target domain
      var x = document.domain;
      this.myChildWindow.postMessage(msg, window.location.origin)// childwin is the targetWindow
      this.myChildWindow.focus();
    }

}
